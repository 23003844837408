import StormwindPortalWrapper from 'StormwindPortalWrapper';
import {generalActions} from 'actions';
import {StormwindPortalLoader} from 'components/StormwindPortalLoader';
import {setBearerToken} from 'conf/axios';
import {Environment} from 'conf/env';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import 'dayjs/locale/cs';
import 'dayjs/locale/da';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fi';
import 'dayjs/locale/fr';
import 'dayjs/locale/hu';
import 'dayjs/locale/id';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/lo';
import 'dayjs/locale/ne';
import 'dayjs/locale/nl';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sk';
import 'dayjs/locale/sq';
import 'dayjs/locale/sr';
import 'dayjs/locale/sv';
import 'dayjs/locale/th';
import 'dayjs/locale/tr';
import 'dayjs/locale/uk';
import 'dayjs/locale/vi';
import 'dayjs/locale/zh';
import {hasFlag} from 'helpers/bitwise';
import hex2rgb from 'hex-rgb';
import useStormwindMessenger from 'hooks/useStormwindMessenger';
import {Messenger} from 'managers/messenger';
import queryString from 'query-string';
import React, {createContext, useEffect, useRef, useState} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {withTranslation} from 'react-i18next';
import {QueryClient, QueryClientProvider} from 'react-query';
import {connect, useDispatch} from 'react-redux';
import {Route, Router, Switch} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toggle/style.css';
import 'reactjs-popup/dist/index.css';
import {EmptyLayout} from 'router/Layouts';
import {MyRoute} from 'router/Routes';
import Auth from 'scenes/Auth';
import WidgetBackgroundCircle from 'scenes/Widget/components/CircleBackground';
import {generalSelector} from 'selectors';
import {authService, jimerService, projectService} from 'services';
import {setIsSavingEvents} from 'services/analytic';
import {F_EXTRA_FLAG_HAS_SESSION_TOKEN} from 'services/jimer';
import {PORTAL_LANG_AUTO, WIDGET_LANG_AUTO} from 'services/project';
import {nativeFontFamilies} from 'shared/front/components/Poke/utils/fonts';
import {Swaler} from 'swaler';
import history from './router/history';
import {
  ROUTE_DALARAN_LIVE,
  ROUTE_WIDGET_AUTH,
  ROUTE_WIDGET_EVOLUTION_ID,
  ROUTE_WIDGET_FEED,
  ROUTE_WIDGET_FEEDBACK,
} from './router/routes.const';
import DalaranLive from './scenes/Dalaran/Live';
import './shared/front/_Tokens.scss';

const logger = new Swaler('Stormwind/Root');

export const AppContext = createContext();

const App = (props) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [overrideTheme, setOverrideTheme] = useState(null);

  const {project} = props;
  const newTheme =
    overrideTheme != null ? overrideTheme : project?.changelogStyle;
  const isWidgetMode = window.location.href.includes('/w/');

  const projectRef = useRef(project);

  const qs = queryString.parse(window.location.search);
  const isPreview = qs.preview === 'true';

  const setHideTopNav = (value) =>
    dispatch(generalActions.setHideTopNav(value));

  const setIsPreviewing = (value) =>
    dispatch(generalActions.setIsPreviewing(value));

  useEffect(() => {
    projectRef.current = project;
  }, [project]);

  useEffect(() => {
    if (isPreview === true) {
      setIsPreviewing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreview]);

  useEffect(() => {
    const setup = async () => {
      try {
        const project = await fetchProject();
        await initializeJimer(project);
        await consumeUrlParameters(project);
        setIsLoading(false);
        setIsSavingEvents(false);
      } catch (err) {
        logger.error(`Could not mount app because of error `, err);
        setIsLoading(false);
        return;
      }
    };

    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProject = async () => {
    let projectDomainName = null;
    let isLoadedByCustomDomain =
      window.location.hostname.includes(Environment.STORMWIND_DOMAIN) === false;

    if (isLoadedByCustomDomain === true) {
      projectDomainName = window.location.hostname;
    } else {
      projectDomainName = window.location.hostname.split(
        `.${Environment.STORMWIND_DOMAIN}`
      )[0];
    }
    try {
      const project = await projectService.getProjectByDomainName(
        projectDomainName
      );

      if (project != null) {
        if (isWidgetMode === true) {
          if (project.widgetLanguage !== WIDGET_LANG_AUTO) {
            props.i18n.changeLanguage(project.widgetLanguage.toLowerCase());
            dayjs.locale(project.widgetLanguage.toLowerCase());
            logger.debug(`Widget language set to ${project.widgetLanguage}`);
          } else {
            dayjs.locale(navigator.language.split('-')[0]);
            props.i18n.changeLanguage(navigator.language.split('-')[0]);
            logger.debug(
              `Widget language set from browser to ${
                navigator.language.split('-')[0]
              } (${navigator.language})`
            );
          }
        } else {
          if (project.portalLanguage !== PORTAL_LANG_AUTO) {
            dayjs.locale(project.portalLanguage.toLowerCase());
            props.i18n.changeLanguage(project.portalLanguage.toLowerCase());
            logger.debug(`Portal language set to ${project.portalLanguage}`);
          } else {
            dayjs.locale(navigator.language.split('-')[0]);
            // Done in src/conf/i18n.conf
            logger.debug(
              `Portal language set from browser to ${
                navigator.language.split('-')[0]
              } (${navigator.language})`
            );
          }
        }
        // For widget language configuration, check WidgetEnter component
        props.setProject(project);
      }
      return project;
    } catch (err) {
      logger.error('Fetching project failed with error ', err);
      throw err;
    }
  };
  /**
   * Retrieve jimer from projects (redux) and
   * create it if not existing
   */

  const initializeJimer = async (project) => {
    const {getProjectJimer} = props;
    const qs = queryString.parseUrl(window.location.href);
    const urlParameters = qs.query;
    let {jimerId, attributes, idy: identify, st, ext, auth} = urlParameters;
    let assignSessionToken = false;

    if (project == null) {
      throw new Error('Feedback project not loaded!');
    }
    // Widget : Auth is provided by undercity, we don't need to perform any requests
    if (auth != null) {
      const jimerId = atob(auth);
      const signResult = await authService.signInJimer(project.uid, jimerId);

      props.setJimer(signResult.jimer);
      props.setProjectJimer(project.uid, {
        uid: signResult.jimer.uid,
        token: signResult.token,
      });
      setBearerToken(signResult.token);
      return;
    }
    let existingJimer = getProjectJimer(project.uid);
    let signResult = {jimer: null, token: null};

    // Check if Jimer always exists in karabor
    if (existingJimer != null) {
      try {
        signResult = await authService.signInJimer(
          project.uid,
          existingJimer.uid
        );
      } catch (err) {
        logger.error('Get jimer failed with error', err);
        signResult = {jimer: null, token: null};
      }
    }
    // Check if a jimer id has been passed in URL and if the user passed is different than the existing one
    if (
      jimerId != null &&
      (existingJimer != null && existingJimer.uid === jimerId) === false
    ) {
      if (jimerId !== 'preview-anonymous-jimer') {
        try {
          signResult = await authService.signInJimer(project.uid, jimerId);
        } catch (err) {
          logger.error('Get jimer failed with error', err);
          throw err;
        }
      } else {
        // We are in case of Dalaran preview, if a jimer exists let's make sure
        // it is not identified so the preview show Stormwind for not identified jimer
        if (signResult.jimer != null) {
          signResult = {
            ...signResult,
            jimer: {
              ...signResult.jimer,
              personalEmail: null,
              externalEmail: null,
            },
          };
        }
      }
    }
    // Check if a session token has been provided
    if (st != null) {
      try {
        signResult = await authService.signInJimerWithSession(project.uid, st);
      } catch (err) {
        if (err?.response?.data?.message === 'JIMER_NOT_FOUND') {
          if (signResult.jimer != null) {
            // Couldn't find a jimer with the given session token and the current jimer has one but it's not his session token, which means a different user is getting logged with another account
            if (
              hasFlag(
                F_EXTRA_FLAG_HAS_SESSION_TOKEN,
                signResult.jimer.extraFlags
              ) === true
            ) {
              signResult = {
                jimer: null,
                token: null,
              };
            }
          }
          // Let's make sure it will be assigned to the current user
          assignSessionToken = true;
        } else {
          logger.error('Sign in jimer with session failed with error', err);
          throw err;
        }
      }
    }
    // Create jimer if not existing
    if (signResult.jimer == null) {
      signResult = await authService.signUpJimer(project.uid);
      logger.debug(
        `Jimer sign up (jimerId=${signResult.jimer.uid} projectId=${project.uid})`
      );
    } else {
      logger.debug(
        `Jimer sign in (jimerId=${signResult.jimer.uid} projectId=${project.uid})`
      );
    }
    props.setJimer(signResult.jimer);
    props.setProjectJimer(project.uid, {
      uid: signResult.jimer.uid,
      token: signResult.token,
    });
    Messenger.sendUpdateProjectJimer({
      projectId: project.uid,
      jimerId: signResult.jimer.uid,
      token: signResult.token,
      isExtension: ext != null,
    });
    setBearerToken(signResult.token);
    // Set custom attributes
    if (attributes != null) {
      try {
        attributes = JSON.parse(window.atob(attributes));
      } catch (err) {
        attributes = {};
        logger.error('Failed to JSON.parse attributes passed in URL', err);
      }
      try {
        await jimerService.updateJimerCustomAttributes(attributes);
        logger.debug('jimer customer attributes updated');
      } catch (err) {
        logger.error('Failed to update jimer custom attributes', err);
      }
    }
    // Set identify external data
    if (identify != null) {
      try {
        identify = JSON.parse(window.atob(identify));
      } catch (err) {
        identify = {};
        logger.error('Failed to JSON.parse identify passed in URL', err);
      }
      try {
        await jimerService.updateJimerExternalData(identify);
        logger.debug('jimer external data updated!');
      } catch (err) {
        logger.error('Failed to update jimer identify', err);
      }
    }
    // Set session token
    if (assignSessionToken === true) {
      try {
        await jimerService.updateJimerSessionToken({
          sessionToken: st,
        });
        logger.debug('session token assigned to current jimer');
      } catch (err) {
        logger.error(
          'failed to assign session token to current jimer ',
          err.message
        );
      }
    }
  };

  const consumeUrlParameters = async () => {
    const {uptJimer} = props;
    const qs = queryString.parseUrl(window.location.href);
    const urlParameters = qs.query;
    let {
      identifyEmail,
      identifyId,
      identifyUsername,
      forceLanguage,
      hideTopNav,
      jimerId, // Consumed earlier in this.initializeJimer
      attributes, // Consumed earlier in this.initializeJimer
      idy, // Consumed earlier in this.initializeJimer
      isp, // Indicate if is preview and should not send events
      ...rest
    } = urlParameters;
    const qsWithoutConsumedParams = {...qs, query: {...rest}};

    if (
      identifyEmail != null ||
      identifyId != null ||
      identifyUsername != null
    ) {
      try {
        const jimer = await jimerService.updateJimerExternalData({
          email: identifyEmail,
          uid: identifyId,
          username: identifyUsername,
        });

        uptJimer(jimer);
      } catch (err) {
        logger.error('Consume idenfity url parameters failed with error ', err);
      }
    }
    if (forceLanguage != null) {
      logger.debug('Forcing languages to ', forceLanguage);
      props.i18n.changeLanguage(forceLanguage.toLowerCase());
      dayjs.locale(forceLanguage.toLowerCase());
    }
    if (hideTopNav === 'true') {
      setHideTopNav(true);
    } else {
      setHideTopNav(false);
    }
    if (isp === 'true') {
      setIsPreviewing(true);
    }
    // Let's remove consumed parameters from URL
    if (
      queryString.stringifyUrl(qsWithoutConsumedParams) !== window.location.href
    ) {
      history.replace({
        search: queryString.stringify(qsWithoutConsumedParams.query),
      });
    }
  };

  const createStyleVariables = (project) => {
    const [header, cards, cta] = project.widgetRoundness.split(';');
    const {general} = newTheme ?? {};
    const {background} = general ?? {};
    const {type, shape, primaryColor, secondaryColor} = background ?? {};

    document.documentElement.style.setProperty(
      '--widgetThemeColor',
      hex2rgb(project.widgetThemeColor, {format: 'array'}).slice(0, 3).join(',')
    );
    document.documentElement.style.setProperty(
      '--widgetPrimaryColor',
      hex2rgb(primaryColor != null ? primaryColor : '#FFFFFF', {
        format: 'array',
      })
        .slice(0, 3)
        .join(',')
    );
    document.documentElement.style.setProperty(
      '--widgetSecondaryColor',
      hex2rgb(secondaryColor || project.widgetPrimaryColor, {format: 'array'})
        .slice(0, 3)
        .join(',')
    );
    document.documentElement.style.setProperty(
      '--widgetRoundnessHeader',
      type === 'shape' ? (shape === 'circular' ? '50%' : '0%') : `${header}`
    );
    document.documentElement.style.setProperty(
      '--widgetRoundnessCards',
      `${cards}`
    );
    document.documentElement.style.setProperty(
      '--widgetRoundnessCta',
      `${cta}`
    );
  };

  useStormwindMessenger({
    onChangelogOverrideTheme: ({theme}) => {
      setOverrideTheme(theme);
    },
    onProjectUpdate: ({project: updatedProject}) => {
      props.setProject({
        ...projectRef.current,
        ...updatedProject,
      });
    },
    onChangelogOverrideView: ({view}) => {
      if (view === 'feed') {
        history.push(`${ROUTE_WIDGET_FEED}?preview=true`);
      } else if (view === 'feedback') {
        history.push(`${ROUTE_WIDGET_FEEDBACK}?preview=true`);
      }
    },
  });

  const queryClient = new QueryClient();

  if (isLoading === true) {
    return (
      <div className="app__loading">
        <StormwindPortalLoader />
      </div>
    );
  }
  if (project == null) {
    return (
      <div className="app__project-not-found fade-in-top">
        <div>
          <div className="title">Ups...</div>It seems that we could not load
          this feedback board currently... Try again later{' '}
          <span role="img" aria-label="ups">
            🤷‍♂️
          </span>
        </div>
      </div>
    );
  }

  const fontFamilySet = new Set();

  const addFontFamily = (rawFontFamily) => {
    const fontFamilies = rawFontFamily.split(',');
    const fontFamily = fontFamilies[0].split(' ').join('+');
    if (
      fontFamilySet.has(fontFamily) === false &&
      nativeFontFamilies.includes(rawFontFamily) === false
    ) {
      try {
        addStylesheetURL(
          `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@300;400;500;600;700`
        );
        fontFamilySet.add(fontFamily);
      } catch (e) {
        console.error(e);
      }
    }
  };

  function addStylesheetURL(url) {
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  createStyleVariables(project);
  return (
    <HelmetProvider>
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <AppContext.Provider value={{theme: newTheme, addFontFamily}}>
            <Route
              path={[
                ROUTE_WIDGET_FEEDBACK,
                ROUTE_WIDGET_FEED,
                ROUTE_WIDGET_EVOLUTION_ID(),
              ]}
              component={WidgetBackgroundCircle}
            />
            <Switch>
              <Route exact path={ROUTE_WIDGET_AUTH} component={Auth} />

              {/* Dalaran */}
              <MyRoute
                exact
                checkAuth
                component={DalaranLive}
                layout={EmptyLayout}
                path={ROUTE_DALARAN_LIVE(':mode')}
              />

              <Route component={StormwindPortalWrapper}></Route>
            </Switch>
          </AppContext.Provider>
        </QueryClientProvider>
      </Router>
    </HelmetProvider>
  );
};

const mapStateToProps = (state) => ({
  project: generalSelector.getProject(state),
  getProjectJimer: (projectId) =>
    generalSelector.getProjectJimer(projectId, state),
});
const mapDispatchToProps = (dispatch) => ({
  setProject: (project) => dispatch(generalActions.setProject(project)),
  setJimer: (jimer) => dispatch(generalActions.setJimer(jimer)),
  setProjectJimer: (project, jimer) =>
    dispatch(generalActions.setProjectJimer(project, jimer)),
  uptJimer: (jimer) => dispatch(generalActions.uptJimer(jimer)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
